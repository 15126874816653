import { Link } from "gatsby-plugin-react-i18next";
import React, { FunctionComponent } from "react";

interface LinkCtaProps {
  to?: string;
  text: string;
  align?: "left" | "right" | "center";
}

const LinkCta: FunctionComponent<LinkCtaProps> = ({
  to,
  text,
  align = "left",
}) => {
  const alignment = {
    left: "",
    right: "text-right",
    center: "text-center",
  }[align];

  const classes = `text-sm text-primary tracking-widest font-bold uppercase hover:underline ${alignment} block`;
  const content = `${text} →`;

  return to ? (
    <Link to={to} className={classes}>
      {content}
    </Link>
  ) : (
    <p className={classes}>{content}</p>
  );
};

export { LinkCta };
