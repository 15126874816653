import React from "react";
import { TitleHeading } from "../typography/TitleHeading";
import BodyText from "../typography/BodyText";
import { HeroCta } from "./HeroCta";
import SideImage from "../layout/SideImage";

interface ImageCtaProps {
  tag: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  image: string;
}

const ImageCta: React.FunctionComponent<ImageCtaProps> = ({
  tag,
  title,
  description,
  image,
}) => {
  return (
    <SideImage theme="dark" image={image} half>
      <div className="md:ml-12 mt-6 md:mt-0">
        <TitleHeading theme="dark" tag={tag} title={title} />
        <BodyText dark justify={false} className="mt-2">
          {description}
        </BodyText>
        <HeroCta />
      </div>
    </SideImage>
  );
};

export default ImageCta;
