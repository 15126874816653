import React from "react";
import Section from "../layout/Section";

interface SideImageProps {
  image: string;
  theme?: "dark" | "white" | "gray";
  children: React.ReactNode;
  half?: boolean;
  imagePosition?: string
  position?: 'right' | 'left'
  id?: string
}

const SideImage: React.FunctionComponent<SideImageProps> = ({
  image,
  children,
  theme,
  half,
  imagePosition = '',
  position = 'left',
  id
}) => {
  return (
    <Section theme={theme} id={id}>
      <div className={`h-72 absolute top-0 ${position === 'left' ? 'left-0' : 'right-0'} w-full lg:h-full ${half ? 'lg:w-1/2' : 'lg:w-1/3'}`}>
        <img className={`w-full h-full object-cover ${imagePosition}`} src={image} />
      </div>
      <div className={`pt-60 lg:pt-0 ${position === 'left' ? 'lg:ml-auto' : 'lg:mr-auto'}  ${half ? 'lg:w-1/2' : 'lg:w-2/3'} ${position === 'left' ? 'lg:pl-6' : 'lg:pr-6'}`}>
        {children}
      </div>
    </Section>
  );
};

export default SideImage;
