import React from "react";
import Title from "../typography/Title";
import Section from "../layout/Section";
import { Pattern } from "../Pattern";
import BodyText from "../typography/BodyText";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { TitleHeading } from "../typography/TitleHeading";
import { useAnimation } from "../../hooks/useAnimate";

interface FeaturesGrid3Props {
  tag: string;
  title: string;
  children?: React.ReactNode;
  elements: {
    icon: React.ReactNode;
    to: string;
    name: string;
    description: string;
  }[];
}

export const FeaturesGrid3: React.FunctionComponent<FeaturesGrid3Props> = ({
  tag,
  title,
  elements,
  children,
}) => {
  const { t } = useTranslation();
  const { id, animate } = useAnimation();

  return (
    <Section
      theme="gray"
      className="flex flex-wrap justify-between lg:px-7"
      id={id}
    >
      <div
        {...animate("fade-right")}
        className="w-full mb-8 lg:w-4/12"
      >
        <TitleHeading tag={tag} title={title} />
        {children && <div className="mt-3 mb-6 lg:mb-0">{children}</div>}
      </div>
      <div className="w-full lg:w-7/12 grid md:grid-cols-2 gap-6 xl:gap-10">
        {elements.map((el, i) => (
          <Link
            {...animate("fade-left", 50 * i)}
            to={el.to}
            className="flex items-center"
            key={i}
          >
            {el.icon || (
              <svg
                className="h-10 w-10 mr-5 text-gray-400 flex-shrink-0	"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
              </svg>
            )}
            <div className="ml-6">
              <Title small>{el.name}</Title>
              {el.description && (
                <BodyText mb={2}>
                  <Trans i18nKey={el.description} components={{ ol: <b /> }} />
                </BodyText>
              )}
              <span className="text-sm text-primary tracking-widest font-medium uppercase hover:underline">
                {t("common:findMore")} →
              </span>
            </div>
          </Link>
        ))}
      </div>

      <Pattern
        type="square"
        className="hidden lg:block absolute h-2/6 w-1/3 bottom-0 left-0 xl:-right-9 text-gray-200 opacity-60"
      ></Pattern>
    </Section>
  );
};
